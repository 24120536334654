<template>
	<div id="dash-solicitudes">
		<div class="container">
			<h2 class="text-center">Solicitudes propuestas a ceder</h2>
			<h3 class="text-center" v-if="exito == false">{{mensaje}}</h3>

			<div :key="solicitud.index" v-for="solicitud of solicitudes">
				<div class="solicitudes" :style="voto()">
					<div class="solicitud">
						<div class="row row-mobile d-block d-sm-none">
							<div class="col">
								<div>
									<span class="label-datos-generales d-none d-sm-block f-white">Nombre</span>
									<span class="datos-generales-mobile f-white">{{ solicitud.nombre }}</span>
								</div>
							</div>
						</div>
						<div class="row row-mobile">
							<div class="col col-sm-2">
								<div>
									<div class="label-datos-generales">Id Solicitud</div>
									<div class="datos-generales">{{ solicitud.id }}</div>
								</div>
							</div>
							<div class="col col-sm-2 d-none d-sm-block">
								<div>
									<div class="label-datos-generales">Nombre</div>
									<div class="datos-generales">{{ solicitud.nombre }}</div>
								</div>
							</div>
							<div class="col col-sm-2">
								<div>
									<div class="label-datos-generales">Monto</div>
									<div class="datos-generales">${{ formatPrice(solicitud.importe_solicitado) }}</div>
								</div>
							</div>
							<!-- <div class="col col-sm-2">
								<div>
									<div class="label-datos-generales">Descripción</div>
									<div class="datos-generales">{{ solicitud.descripcion_solicitud }}</div>
								</div>
							</div> -->
							<div class="col col-sm-2">
								<div>
									<div class="label-datos-generales">Tipo Solicitud</div>
									<div class="datos-generales">{{ solicitud.tipo_solicitud }}</div>
								</div>
							</div>
							<div class="col col-sm-2">
								<div>
									<div class="label-datos-generales">Cedida</div>
									<div class="datos-generales" v-if="solicitud.organicacion_cesion_creditos">{{ solicitud.organicacion_cesion_creditos.nombre }}</div>
									<div class="datos-generales" v-else> - </div>
								</div>
							</div>
							<button
								:data-target="'#solicitud-collapse-'+ solicitud.id"
								aria-controls="collapseSolicitud"
								aria-expanded="false"
								class="btn col d-none d-sm-block text-right"
								data-toggle="collapse"
								type="button"
							>
								<img alt="logo" class="arrow-down" src="@/apps/comite/assets/img/arrow-down.svg" width="40px" />
							</button>
						</div>
						<button
							:data-target="'#solicitud-collapse-'+ solicitud.id"
							aria-controls="collapseSolicitud"
							aria-expanded="false"
							class="btn col d-block d-sm-none"
							data-toggle="collapse"
							type="button"
						>
							<img alt="logo" class="arrow-down" src="@/apps/comite/assets/img/arrow-down.svg" width="40px" />
						</button>
					</div>
					<div :id="'solicitud-collapse-' + solicitud.id" class="collapse">
						<div class="card card-body">
							<div>
								<div class="text-center">
									<h4>Generales:</h4>
								</div>
								<div class="generales-data">
									<span class="heanding-mobile">Descripción</span>
									<span class="col text-right">{{ solicitud.descripcion_solicitud }}</span>
								</div>
								<div class="generales-data">
									<span class="heanding-mobile">Medio de información</span>
									<span class="col text-right" > {{ solicitud.medio_informacion.canal }} </span>
								</div>
								<div class="generales-data">
									<span class="heanding-mobile">Promotor</span>
									<span class="col text-right" v-if="solicitud.promotor == null">Sin promotor</span>
									<span class="col text-right" v-else>{{ solicitud.promotor.nombre }}</span>
								</div>
								<div class="generales-data">
									<span class="heanding-mobile">Ingresos mensuales</span>
									<span class="col text-right">${{ formatPrice(solicitud.ingreso_mensual) }}</span>
								</div>
								<div class="generales-data">
									<span class="heanding-mobile">Importe preautorizado</span>
									<span class="col text-right">${{ formatPrice(solicitud.importe_pre_autorizado) }}</span>
								</div>
								<div class="generales-data">
									<span class="heanding-mobile">Fecha cesión</span>
									<span class="col text-right">{{ solicitud.create_cesion_credito }}</span>
								</div>
								<div class="generales-data">
									<span class="heanding-mobile">Usuario que cedio crédito</span>
									<span class="col text-right" v-if="solicitud.organicacion_cesion_creditos">{{ solicitud.acreditador_cesion_creditos.nombre }}</span>
									<span class="col text-right" v-else> - </span>
								</div>
								<div class="acciones-general col">
									<div class="row">
										<div class="col">
											<ul>
												<li>
													<a title="Ceder" @click="clickCederSolicitud(solicitud)" class="btn-primay">
														<img alt="logo" class="icon-action" src="@/apps/comite/assets/img/aprove.svg" />
													</a>
												</li>
												<!-- <li>
													<a title="Descartar" @click="clickDescartarSolicitud(solicitud)" class="btn-primay">
														<img alt="logo" class="icon-action" src="@/apps/comite/assets/img/cancel.svg" />
													</a>
												</li> -->
												<li>
													<a title="Ver Comentarios" @click="obtenerComentarios(solicitud)" class="btn-primay">
															<img alt="comentarios" class="icon-action" src="@/apps/comite/assets/img/coments.svg" />
													</a>
												</li>
											</ul>
										</div>
										<!-- <div class="col detalle">
											<a @click.prevent="detalles_solicitud(solicitud.id)">Más detalles <img src="@/apps/comite/assets/img/detail.svg" alt="logo" width=15%></a>
										</div> -->
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<Modal v-if="modal_ceder_solicitud" :options="modalConfig" @close="modal_ceder_solicitud=false" class="bpb-modal">
				<h2 class="title">Ceder Solicitud: {{solicitud.id}}</h2>
				<div class="body">
					<div class="row form-group">
						<div class="col-sm-12">
							<label>¿Deseas Ceder esta solicitud a FRD?</label>
							<hr/>
							<div class="datos-generales" v-if="solicitud.organicacion_cesion_creditos">
								<label>Esta solicitud ya está cedida a {{ solicitud.organicacion_cesion_creditos.nombre }}.</label>

								<div class="label-datos-generales">¿Deseas volver a ceder esta solicitud?</div>
								<div class="datos-generales">
									<BSwitch v-model="volver_a_ceder" id="volver_a_ceder" name="volver_a_ceder" texton="Sí" textoff="No" />
								</div>
							</div>
						</div> 
					</div>
				</div>
				<div class="footer">
					<button class="btn principal-btn col-sm-12" @click="cederSolicitud(2)">Ceder</button>
				</div>
			</Modal>

			<Modal v-if="modal_descartar_solicitud" :options="modalConfig" @close="modal_descartar_solicitud=false" class="bpb-modal">
				<h2 class="title">Descartar Solicitud: {{solicitud.id}}</h2>
				<div class="body">
					<div class="row form-group">
						<div class="col-sm-6">
							<label for="monto">¿Deseas descartar esta solicitud?</label>
						</div>
					</div>
				</div>
				<div class="footer">
					<button class="btn principal-btn col-sm-12" @click="cederSolicitud(1)">Descartar</button>
				</div>
			</Modal>

			<Modal v-if="modal_ver_comentarios" :options="modalConfig" @close="modal_ver_comentarios=false" class="bpb-modal">
			<h2 class="title">Comentarios</h2>
			<div class="body">
				<div v-for="voto of comentarios" class="row" :key="voto.id">
					<div class="col-sm-12">
            <h6>{{ voto.acreditador.nombre }} :</h6>
            <p> {{ voto.observacion }} </p>
            <p v-if="voto.autorizado"> ${{ formatPrice(voto.autorizado) }} </p>
					</div>
				</div>
			</div>
		</Modal>

		</div>
	</div>
</template>

<script>
import api from '@/apps/comite/api/comite'
import comite from '@/apps/comite/api/comite'
import Modal from '@/components/Modal'
import BSwitch from '@/components/Form/Switch'

export default {

	components: {
		Modal, BSwitch
	},

	data: () => ({
		exito: true,
		mensaje: '',
		estatus: null,
		solicitudes: [],
		solicitud: null,
		comite: null,
		modal_ceder_solicitud: false,
		modal_descartar_solicitud: false,
		modal_ver_comentarios: false,
		comentarios: [],
		modalConfig: {
			width: '40vw',
			close: true,
		},
		volver_a_ceder: 0,
	}),

	async mounted() {
		try{
			
			var solicitudes = await api.get_solicitudes_a_ceder();
			
			this.$log.info('solicitud', solicitudes.data);
			this.solicitudes = solicitudes.data.solicitudes;
			this.comite = solicitudes.data.id_comite;

			if(solicitudes.data?.solicitudes?.length == 0){
				this.exito = false
				this.mensaje = 'No hay solicitudes para mostrar.'
			}
		}catch(err) {
			if(err.response?.status == 401){
				this.exito = false
				this.mensaje = 'Acceso no autorizado'
			}
			console.log(err);
		}
	},

	methods: {
		clickCederSolicitud(solicitud){
			this.solicitud = solicitud
			this.modal_ceder_solicitud = true
		},

		clickDescartarSolicitud(solicitud){
			this.solicitud = solicitud
			this.modal_descartar_solicitud = true
		},

		cederSolicitud: function(organizacion_id) {
			comite.ceder_solicitud({
				cesion_credito_organizacion_id: organizacion_id,
				volver_a_ceder: this.volver_a_ceder,
			}, this.solicitud.id)
			.then(res => {
				this.$log.info('res', res);
				this.modal_ceder_solicitud = false;
				this.$notify({
					group: 'alert'
					,type: 'success'
					,title: 'Decisión.'
					,text: 'Tu decisión se guardó correctamente.'
					,duration: 5000
				})

				this.modal_ceder_solicitud = false
				this.modal_descartar_solicitud = false
			})
			.catch(err => {
				this.$log.info('err', err);
				this.$helper.showAxiosError(err,'Error');
				
				this.modal_ceder_solicitud = false
				this.modal_descartar_solicitud = false
			})
		},

		obtenerComentarios: async function(solicitud) {
			this.solicitud = solicitud
			this.modal_ver_comentarios = true
			try{
				const res = await comite.getVotacion(this.solicitud.id)
				this.$log.info('res', res)
				this.comentarios = res.data
				if(res.data.length == 0){
						this.$notify({
						group: 'alert'
						,type: 'warning'
						,title: 'Comentarios FRD'
						,text: 'No hay comentarios para esta solicitud'
						,duration: 5000
					})
				}
				this.modal_ver_comentarios = true;
			}catch(err) {
				this.$log.info('err', err.response);
				this.$notify({
					group: 'alert'
					,type: 'error'
					,title: 'Error'
					,text: (err.response.status == 404 ? 'No hay comentarios para esta solicitud' : err.response.data.message)
					,duration: 5000
				})
			}
		},

		voto() {
			console.log(arguments)
		},

	  formatPrice(value) {
	        let val = (value/1).toFixed(0).replace(',', '.')
	        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
	  },
	  
		voto: function(solicitud) {
			return 'background-color: #24b361';
		},

		detalles_solicitud: async function(id) {
			await api.solicitud(id)
	      	.then(res => {
		        this.$log.info('res solicitud', res);
		        let solicitud = res.data;
		        solicitud['id'] = id;
		        solicitud['comite_id'] = this.comite;
		        solicitud['historico'] = false;
		        this.$store.commit('set', { solicitud });
		        this.$router.push({name: 'comite_resumen_modelo'});
	      	})
	      	.catch(err => {
	        	this.$log.info('err', err)
	      	})
		}
	}
}
</script>

<style lang="scss" scoped>

#dash-solicitudes {
	background-color: $white;
	// min-height: 100vh;
	margin-bottom: 2rem;
	.container{
		margin-bottom: 2rem;
	}
}
h2 {
	color: $text-primary;
	padding: 1rem 0;
}
.solicitudes {
	background: $color-blue;
	margin-top: 2rem;
	padding: 2rem 0 0;
	color: $white;
	border: none;
  box-shadow: 0 2px 18px 1px rgba(49,53,72,.1);
  border-radius: .45rem;

	.solicitud {
		padding: 0% 3% 2%;

		button {
			&:focus {
				outline: none !important;
				box-shadow: none;
			}
			&[aria-expanded="true"] {
				.arrow-down {
					transition: all 0.8s;
					transform: rotate(-180deg);
				}
			}
			&[aria-expanded="false"] {
				.arrow-down {
					transition: all 0.8s;
				}
			}
		}
		
		.f-white {
      color: #fff !important;
    }
	}

	@include mq-max(mobile-big) {
		.solicitud {
			text-align: center;
			.row-mobile {
				border-bottom: 1px dashed;
				margin-bottom: 2%;
				padding-bottom: 2%;
			}
			button {
				padding: 0%;
			}
		}
	}
}
.datos-generales {
	font-family: $nunito-regular;
}
.datos-generales-mobile {
	font-family: $nunito-semibold;
	font-size: 16px;
}
.label-datos-generales {
	font-family: $nunito-bold;
	font-size: 18px;
}
.generales-data {
	display: flex;
	justify-content: space-between;
	.text-right {
		font-weight: 700;
		padding-right: 0;
		color: $text-primary;
	}
	.heanding-mobile {
		color: $text-primary;
		font-size: 16px;
	}
}
.acciones-general {
	margin-top: 5%;
	padding-left: 0;
	ul {
		display: flex;
		padding-left: 0;
		justify-content: center;
		margin-bottom: 0;

		li {
			list-style: none;
			margin-right: 8%;
			.icon-action {
				width: 40px;
			}
		}
		@include mq-min(tablet) {
			li {
				padding-right: 4%;
				margin-right: 2%;
			}
		}
	}

	.detalle {
		text-align: right;
		font-family: $nunito-regular;
		color: $color-blue;
		padding-right: 0%;
		align-items: center;
		display: flex;
		justify-content: flex-end;
		a{
			min-width: 150px;
			color: $color-blue;
			text-decoration: underline;
			&:hover{
				color: $color-green;
			}
		}
		img {
			&:hover {
				color: $color-green;
			}
		}
	}
	&:last-of-type{
		margin-bottom: 2rem;
	}
}
</style>